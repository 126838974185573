import React from 'react';

import Layout from '../components/layout/Layout';
import Container from '../components/layout/Container';
import SEO from '../components/misc/seo';

function BeratungUndDiagnostik() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <section className="bg-white text-green-800">
        <div className="py-24">
          <Container>
            <div className="text-center mb-12">
              <h1 className="font-serif text-4xl sm:text-6xl">
                Beratung und Diagnostik
              </h1>
            </div>
            <div className="md:max-w-4xl mx-auto">
              <p>
                Meine heilpädagogische Diagnostik basiert auf einem
                verstehensorientierten Ansatz: Zuerst das betroffene Kind
                ganzheitlich in seinem Gesamtsystem verstehen, kennenlernen und
                dann fördern, ohne seine Persönlichkeit zu verletzen. Dieser
                Diagnostikansatz besteht aus einer intensiven Beziehungsarbeit
                und verschiedenen Methoden und Verfahren. Das bedeutet, dass
                bereits jede Diagnostik Förderung ist und umgekehrt.
              </p>
              <p className="my-4">Konkretisiert heißt das:</p>
              <ul className="list-disc leading-8">
                <li>Sie umfassen alle Bereiche der kindlichen Endwicklung,</li>
                <li>
                  beinhalten die Beobachtungen der Eltern-Kind-Interaktion,
                </li>
                <li>sind handlungs- und alltagsorientiert</li>
                <li>
                  und zielen auf die Teilhabe des Kindes in seinem Umfeld.
                </li>
                <li>
                  Sie bedienen sich heilpädagogischer Befunderhebung, der
                  Beobachtung sowie normorientierter Verfahren, wie
                  standardisierte Screenings und Testverfahren zur Feststellung
                  der aktuellen Problematik.
                </li>
                <li>Sie dienen als Grundlage der Förderpläne.</li>
                <li>
                  Sie werden unter Einbeziehung der Bezugspersonen erbracht.
                </li>
              </ul>
            </div>
          </Container>
        </div>
      </section>
    </Layout>
  );
}

export default BeratungUndDiagnostik;
